@import './_mixins.scss';


.doctor-account {
    &__filters {
        span {
            font-size: 1.1rem;
            font-weight: 600;
        }
        &--header {
            @include flex-just-between;
            align-items: center;
            margin-bottom: 30px;
            h3 {
                margin-bottom: 0;
            }      
        }
        &--btns {
            display: flex;
            padding: 1vh 0;
            gap: 1.4vw;
            .active-filter {
                background-color: var(--primary-color);
                color: var(--white-color);
                border: 1px solid var(--white-color);
            }
        }
        &--input {
            padding: 1vh 0;
            label {
                margin-right: 1.4vw;
            }
            .btn {
                margin-top: 8px;
            }
        }  
    }
    &__list {
        @include flex-column;
        gap: 2vh;
        margin-top: 20px;
    }
}

@media (max-width: 1099px) {
    .doctor-account__list {
        flex-direction: row;
        flex-wrap: wrap; 
    }
}

@media (max-width: 768px) {
    .doctor-account__filters {
        flex-direction: column;
        gap: 20px;
        &--btns {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@media (max-width: 420px) {
    .doctor-account__filters {
        &--header {
            flex-direction: column;
            h3 {
                margin-bottom: 10px;
            }
        }
    }
}