.specialities {
    display: grid;
    grid-template-columns: repeat(auto-fit, 290px);
    gap: 12px;
    justify-content: center;
    padding: 0 10vw;
    li {
        text-align: center;
        background-color: var(--primary-color);
        transition: all .3s ease-out;
        &:hover {
            transform: scale(1.06);
        }
        a {
            display: block;
            font-family: var(--menu-font-family);
            color: var(--white-color);
            text-transform: uppercase;
            letter-spacing: 2px;
            width: 100%;
            height: 100%;
            padding: 0.8rem 2rem;
        }
    }
}

@media (max-width: 768px) {
    .specialities {
        grid-template-columns: repeat(auto-fit, 200px);
        padding: 0;
    }
}