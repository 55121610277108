@import './_mixins.scss';

section {
    padding: 60px 8vw;
    &.filters {
        @include flex-just-between;
        label {
            @include flex-column;
        }
        span {
            font-weight: 600;
        }
    }
    &.article__section p {
        margin: 20px 0;
        white-space: pre-wrap;
    }
    .doctors {
        @include flex-column;
    }
}

@media (min-width: 1200px) {
    .doctors {
        padding: 0 10vw;
    }
}

@media (max-width: 768px) {
    section {
        padding: 20px 3vw;
    }
    .filters {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .doctors {
        padding: 20px 3vw;
    }
}