@import './_mixins.scss';


.doctor-account__list--item {
    display: flex;
    border-radius: 6px; 
    border: 1px solid var(--primary-color);
    p {
        display: flex;
        align-items: center;
        min-width: 25%;
    }
    a {
        width: 100%;
    }
    &-name {
        text-transform: capitalize;
    }
}
    
@media (min-width: 1100px) {
    .doctor-account__list--item {
        p {
            padding: 0 1.6rem;
            margin: .5rem 0;
            &:not(:last-child) {
                border-right: 1px solid var(--primary-color)
            }
        }
    }
}

@media (max-width: 1099px) {
    .doctor-account__list--item {
        @include flex-column;
        gap: 10px;
        width: 100%;
        flex-grow: 1;
        padding: .8rem;
        p {
            padding: .5rem 0;
            &:not(:last-child) {
                border-bottom: 1px solid var(--primary-color)
            }
        }
        .btn {
            width: fit-content;
            align-self: flex-end;
        }
    }
}


@media (max-width: 576px) {
    .doctor-account__list--item p {
        padding: .4rem .4rem;
        word-break: break-word;
    }
}