.react-calendar {
    margin: 0 auto 24px;
    > div {
        margin: 0 auto;
    }
    &__navigation__arrow {
        min-width: 34px !important;
        width: 34px !important;
        padding: 0.8rem .2rem;
    }
    &__navigation__label {
        padding: 0.8rem .4rem;
    }
    &__month-view__days > .react-calendar__tile,
    &__month-view__weekdays__weekday {
        flex-basis: 20% !important;
        max-width: 20% !important;
    }
    &__month-view__weekdays__weekday:nth-child(6),
    &__month-view__weekdays__weekday:nth-child(7) {
        display: none !important;
    }
    &__month-view__days__day--weekend {
        display: none !important;
    }
    &__calendar__navigation__arrow {
        min-width: 30px !important;
    }
    
}

@media (min-width: 400px) {
    .react-calendar {
        &__viewContainer {
            button {
                min-width: 70px !important;
            }
        }
    }
}

@media (max-width: 399px) {
    .react-calendar {
        &__viewContainer {
            button {
                min-width: 50px !important;
            }
        }
        &__month-view__days__day {
            padding: 0.8rem .4rem;
        }
    }
}

