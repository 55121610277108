@import './_mixins.scss';

nav {
    @include flex-just-between;
    align-items: center;
    gap: 1.4vw;
    background-color: var(--primary-color);
    padding: 1rem 8vw;
    letter-spacing: 1px;
    color: var(--white-color);
    min-height: 75px;
    .logo {
        z-index: 3;
        a {
            color: var(--white-color);
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            .fa-stethoscope {
                margin-right: 10px;
            }
            span {
                font-size: 2rem;
                font-family: var(--logo-font-family);
            }
        }
    }
    .svg-inline--fa {
        height: 40px; 
    }
    .buttons-group {
        @include flex-center-center;
        gap: 2vw;
        margin-right: 0;
        button {
            color: var(--white-color);
        }
    }
}

@media (max-width: 768px) {
    nav {
        padding: 1rem 3vw;
    }
}

@media (max-width: 350px) {
    .logo span {
        display: none;
    }
}
