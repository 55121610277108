@import './_mixins.scss';

.doctor__item {
    display: flex;
    border-bottom: 1px solid var(--primary-color);
    padding: 40px 0;
    &--picture {
        margin: 0 auto;
        img {
            width: 300px;
            height: auto;
            margin: 0 auto;
        }
    }
    &--details {
        @include flex-column;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        gap: 24px;
    }
    &--description {
        @include flex-column;
        align-items: center;
        gap: 20px;
        padding: 0 5vw;
        h2, h3 {
            text-align: center;
        }
        h3 {
            margin-bottom: 8px;
        }
        div {
            p, h3 {
                text-align: center;
            }
        }
    }
}

@media (max-width: 1000px) {
    .doctor__item--picture img {
        width: 250px;
    }
}

@media (max-width: 768px) {
    .doctor__item {
        flex-wrap: nowrap;
    }
    .doctor__item--picture img {
        width: 250px;
        margin: 0 auto;
    }
}

@media (max-width: 576px) {
    .doctor__item {
        flex-wrap: wrap;
    }
    .doctor__item--picture img {
        width: 200px;
    }
}