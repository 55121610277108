@import './_mixins.scss';

.react-calendar__tile,
.react-calendar__navigation__arrow,
.react-calendar__navigation__label,
.btn {
    font-family: var(--menu-font-family);
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-align: center;
    min-width: 140px;
    border-radius: 6px;
    padding: 0.8rem 2rem;
    transition: all .3s ease-out;
}

.react-calendar__tile,
.react-calendar__navigation__arrow,
.react-calendar__navigation__label,
.blue-btn {
    color: var(--white-color);
    background-color: var(--primary-color);
    border: 1px solid var(--white-color);
    &:not(.menu-btn):hover {
        color: var(--primary-color);
        background-color: var(--white-color);
        border: 1px solid var(--primary-color);
    }
}

.white-btn {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    &:hover {
        background-color: var(--primary-color);
        color: var(--white-color);
        border: 1px solid var(--white-color);
    }
}

.red-btn {
    background-color: var(--red-color);
    border: 1px solid var(--red-color);
    border-radius: 6px;
    color: var(--white-color);
    &:hover {
        background-color: var(--white-color);
        border: 1px solid var(--red-color);
        color: var(--red-color);
    }
}

.back-to-top {
    position: fixed;
    bottom: 2px;
    right: 8vw;
    &:hover {
        border: 1px solid var(--primary-color);
    }
}

.reservation__form-btn {
    margin-right: 0;
    align-self: flex-end;
}

.menu-btn {
    @include flex-center-center;
    background-color: transparent;
    border: none;
    width: 50px;
    min-width: unset;
    height: 44px;
    z-index: 12;
    svg {
        transition: all .3s ease-out;
    }
    &:hover svg {
        color: var(--red-color);
    }
}

.lang-btn {
    font-size: 1.4rem;
    min-width: 74px;
    padding: .8rem .6vw;
    color: var(--white-color);
    background-color: transparent;
    border: 1px solid var(--white-color);
    &:hover {
        color: var(--primary-color);
        background-color: var(--white-color);
        border: 1px solid var(--white-color);
    }
}


@media (min-width: 1300px) {
    .menu-btn {
        display: none;
    }
}

@media (max-width: 768px) {
    .back-to-top {
        right: 3vw;
    }
    .lang-btn {
        min-width: 64px;
    }
}