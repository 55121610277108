@import './_mixins.scss';

.login__form {
    @include flex-column;
    align-items: center;
    gap: 16px;
    width: 50vw;
    margin: 0 auto;
    gap: 24px;
    div {
        display: flex;
        gap: 3vw;
        div {
            @include flex-column;
            gap: 20px;
        }
    }
    label {
        @include flex-column;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .login__form .btn {
        align-self: center;
    }
}