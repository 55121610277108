:root {
  --primary-color: rgb(87, 153, 191);
  --secondary-color: rgb(105, 105, 114);
  --primary-font-family: 'Noto Sans Display', sans-serif;
  --secondary-font-family: 'Dosis', sans-serif;
  --menu-font-family: 'Abril Fatface', cursive;
  --logo-font-family: 'Titan One', cursive;
  --white-color: rgb(255, 255, 255);
  --red-color: rgb(226, 35, 38);
  --loader-color: rgba(87, 153, 191, 0.301);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--primary-font-family);
  font-size: 16px;
}

main {
  min-height: calc(100vh - 150px);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.1rem;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 60px;
  height: 4px;
  background-color: var(--red-color);
  margin: 0 auto;
}

h3 {
  font-size: 1.4rem;
}

p {
  font-size: 1.1rem;
  line-height: 1.7rem;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

label {
  font-weight: 600;
}

select {
  text-transform: uppercase;
}

select, input {
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  background-color: transparent;
  padding: .4rem 1rem;
  width: 280px;
  height: 42px;
  margin-top: 8px;
}

select, button, a {
  cursor: pointer;
}

@media (max-width: 1400px) {
  * {
    font-size: 14px;
  }
  h2 {
    margin-bottom: 30px;
  }
  select, input {
    width: 200px;
  }
}

@media (max-width: 576px) {
  * {
    font-size: 12px;
  }
}