@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-just-between {
    display: flex;
    justify-content: space-between;
}