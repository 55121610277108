@import './_mixins.scss';

.reservation__form {
    @include flex-column;
    width: 50vw;
    margin: 0 auto;
    label {
        @include flex-column;
        margin-bottom: 24px;
        > span {
            font-size: .8rem;
            color: var(--secondary-color);
            margin-top: 4px;
        }
    }
    .date-input {
        pointer-events: none;
    }
    .error-msg {
        font-size: .86rem;
        font-weight: 600;
        color: var(--red-color);
        margin-top: -18px;
        margin-bottom: 12px;
    }
    .error-border {
        border: 2px solid var(--red-color);
    }
    .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--loader-color);
        z-index: 3;
    }
    .hidden {
        display: none;
    }
}

@media (max-width: 768px) {
    .reservation__form {
        width: 100%;
    }
}