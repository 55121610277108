@import './_mixins.scss';

footer {
    @include flex-just-between;
    align-items: center;
    background-color: var(--primary-color);
    padding: 1rem 8vw;
    color: var(--white-color);
}

@media (min-width: 769px) {
    footer {
        p {
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    footer {
        padding: 1rem 3vw;
        p {
            width: 57%;
        }
    }
}