@import './_mixins.scss';


.doctor-account {
    article {
        padding: 5vh 0;
        border-bottom: 1px solid var(--primary-color);
        span {
            font-size: 1.1rem;
            font-weight: 600;
        }
    }
}

@media (max-width: 576px) {
    .doctor-account section {
        padding: 3vh 0;
    }
}