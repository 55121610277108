@import './_mixins.scss';

body {
    position: relative;
}

.nav__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;
    gap: 1.4vw;
}

.nav__item {
    a {
        font-family: var(--menu-font-family);
        font-size: 1.4rem;
        color: var(--white-color);
        padding: .8rem 1.2vw;
        border-radius: 6px;
        transition: all .3s ease-out;
        border: 1px solid var(--primary-color);
        width: 100%;
        &:hover {
            color: var(--primary-color);
            background-color: var(--white-color);
            border: 1px solid var(--white-color);
        }
    }
}

@media (min-width: 1300px) {
    .nav__item a {
        font-family: var(--menu-font-family);
        font-size: 1.4rem;
        color: var(--white-color);
        background-color: transparent;
        padding: .8rem 1.2vw;
        border-radius: 6px;
        transition: all .3s ease-out;
    }
    .nav__item--reservation a {
        border: 1px solid var(--white-color);
    }
    .nav__item .active {
        color: var(--primary-color);
        background-color: var(--white-color);
    }
    .nav__item--doctor-zone a {
        background-color: var(--red-color);
    }
    .nav__item--doctor-zone a:hover {
        color: var(--red-color);
    }
    
    .nav__item--doctor-zone .active {
        color: var(--red-color);
    }
}

@media (max-width: 1299px) {
    .nav__menu {
        display: none;
    }
}

.mobile-menu {
    display: block;
    z-index: 2;
    position: absolute;
    top: 75px;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    @include flex-column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    padding-top: 40px;
}

.mobile-menu:after {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    background-color: var(--primary-color);
    z-index: -1;
}